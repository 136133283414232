.center {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    margin-top: 50px;
}

#bg {
  position: fixed;
  top: 0;
  left: 0;

  /* Preserve aspet ratio */
  min-width: 100%;
  min-height: 100%;
}